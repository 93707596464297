<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="96px">
      <topOperatingButton
        :isExamineBtn="true"
        :showAudit="false"
        id="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditForm"
        @getQuit="$router.go(-1)"
      >
        <template v-slot:specialDiyBtn>
          <el-button size="mini" @click="handleEvent('openBatchChangeScore')">
            批量调整积分
          </el-button>
          <el-button size="mini" @click="handleEvent('batchDel')"> 批量删除 </el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
        :billStatus="options.billStatus"
      >
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="方案编号" required>
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="options.billNo"
                placeholder="方案编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="方案日期" required>
              <el-date-picker
                class="inputWidth"
                size="mini"
                v-model="options.billDate"
                type="date"
                placeholder="方案日期"
                :disabled="['2', '3'].includes(options.billStatus)"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="本次调整积分" label-width="120px">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="['2', '3'].includes(options.billStatus)"
                v-model="options.adjustScore"
                placeholder="本次调整积分"
                @input="
                  limitInputlength(
                    options.adjustScore,
                    options,
                    'adjustScore',
                    true,
                    8,
                    true
                  )
                "
                :max="9999999"
              />
            </el-form-item>
            <el-form-item label="方案备注" prop="billRemark">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="options.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
                :disabled="['2', '3'].includes(options.billStatus)"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="会员充值列表" id="basicInformation">
        <template slot="cardContent">
          <div class="table">
            <!-- 可编辑表格 -->
            <EditTable :options="options" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import EditTable from "@/components/editTable"; //可编辑表格
import Dialog from "@/components/Dialog";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import { getBillNo } from "@/api/codeRule";
import {
  scoreAdjustSave,
  scoreAdjustUpdate,
  scoreAdjustUpdateStatus,
  scoreAdjustGetDetail,
} from "@/api/vip/bill/score";
import { limitInputlength, deepClone, fcount } from "@/utils";
import { uniqWith } from "lodash"; //去重
import { getFormattedDate } from "@/utils/newDate";

export default {
  name: "ScoreDetail",
  components: {
    cardTitleCom,
    EditTable,
    topOperatingButton,
    Dialog,
  },
  data() {
    return {
      loading: false, //遮罩层
      //表格配置
      options: {
        adjustScore: undefined, //本次调整积分
        billRemark: undefined, //备注
        billDate: "", // 方案日期（当前日期）
        billNo: undefined, // 方案编号
        billId: undefined, //方案id
        mutiSelect: true, //是否多选
        status: undefined, //状态
        list: [{}],
        check: [], //选中的数据
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 0, //表格高度
        retainColumn: true, //保留表格最后一行
        tableIndex: undefined,
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            type: "remoteSelect",
            click: "clickVip",
            minWidth: 150,
            align: "center",
            option: this.$select({
              key: "vip",
              option: {
                option: {
                  label: "vipNo",
                  value: "vipNo",
                  model: "vipNo",
                  change: (propValue, row) => {
                    if (row && this.options.tableIndex != undefined) {
                      const isRepetition = this.options.list.some(
                        (item) => item.vipId == row.vipId
                      );
                      if (!isRepetition) {
                        this.options.columns[0].option.showItem = [row];
                        this.$set(this.options.list, this.options.tableIndex, {
                          ...row,
                          agoUsableScore: row.usableScore,
                          adjustScore: this.options.adjustScore
                            ? this.options.adjustScore
                            : "",
                          usableScore: fcount(
                            [row.usableScore, this.options.adjustScore],
                            "+"
                          ),
                        });
                      } else {
                        this.options.list[this.options.tableIndex] = {};
                        this.$message.error("请选择不同的会员");
                      }
                    }
                  },
                  tableChange: (propValue, row) => {
                    this.handleEvent("getSelectData", row);
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择会员",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: {
                          ...this.$dialog({ key: "vip" }),
                          table: {
                            ...this.$dialog({ key: "vip" }).table,
                            mutiSelect: true,
                          },
                        },
                      },
                    },
                  ],
                },
              },
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "agoUsableScore",
            label: "可用积分数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "adjustScore",
            label: "本次调整积分数",
            minWidth: 120,
            align: "center",
            type: "input",
            input: "sumScore",
            isMinus: true,
            disabled: false,
            rules: true,
          },
          {
            prop: "usableScore",
            label: "调整后可用积分",
            minWidth: 120,
            align: "center",
          },
        ],
        summary: ["agoUsableScore", "adjustScore", "usableScore"],
      },
      //弹窗配置
      dialogOptions: {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      rules: {
        billNo: [
          {
            required: true,
            message: "请输入方案编号",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [{ required: true, message: "请输入制单日期", trigger: "blur" }],
      },
      pageH: 0, //页面高度
      btnsH: 0, //搜索框高度
      basicH: 0, //基础框高度
      paginationH: 50, //分页高度
    };
  },
  watch: {
    "options.billStatus": {
      handler(val) {
        const disabledColumns = ["2", "3"].includes(val);
        this.options.columns[0].disabled = disabledColumns;
        this.options.columns[3].disabled = disabledColumns;
        this.options.status = val;
      },
      immediate: true,
    },
  },
  activated() {
    this.handleResize(); //自适应高度
    this.getDetail();
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.getDetail();
  },
  beforeDestroy() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    //保留小数位
    limitInputlength,
    //获取详情
    async getDetail() {
      this.reset();
      if (this.$route.query.type === "Update" && this.$route.query.billId) {
        this.loading = true;
        try {
          //获取方案详情
          const { data } = await scoreAdjustGetDetail(this.$route.query.billId);
          this.options.columns[0].option.showItem = data.billDetailItems;
          //方案赋值
          this.options = {
            ...this.options,
            ...data,
            list: data.billDetailItems,
          };
        } catch (err) {}
        this.loading = false;
      } else {
        this.getBillno();
      }
    },
    //自适应获取高度
    async handleResize() {
      setTimeout(() => {
        this.pageH = document.getElementById("wrap").offsetHeight;
        this.btnsH = document.getElementById("topOperatingButton").offsetHeight;
        this.basicH = document.getElementById("basicInformation").offsetHeight;
        this.options.tableHeight =
          this.pageH - this.btnsH - this.basicH - this.paginationH - 68;
      }, 10);
    },
    async handleEvent(type, row) {
      switch (type) {
        case "clickVip":
          if (["2", "3"].includes(this.options.billStatus)) return;
          //弹窗配置
          this.dialogOptions = {
            index: row.index,
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vip" }),
          };
          break;
        case "getSelectData":
          if (!row) return;
          let { check } = row;
          if (check.length <= 0) return;
          this.options.columns[0].option.showItem = check;
          // this.options.columns[0].option.showItem = list
          let list = check.map((item) => ({
            ...item,
            agoUsableScore: item.usableScore,
            adjustScore: this.options.adjustScore ? this.options.adjustScore : "",
            usableScore: fcount([item.usableScore, this.options.adjustScore], "+"),
          }));
          let tableIndex = this.options.tableIndex;
          console.log("什么鬼", tableIndex);
          this.options.tableIndex = undefined;
          this.options.list.splice(tableIndex, 1, ...list);
          this.options.list = uniqWith(this.options.list, (x, y) => x.vipId == y.vipId);
          break;
        case "sumScore":
          if (!this.options.list[this.options.tableIndex].vipId) return;
          this.options.list[this.options.tableIndex].usableScore = fcount(
            [
              this.options.list[this.options.tableIndex].agoUsableScore,
              this.options.list[this.options.tableIndex].adjustScore,
            ],
            "+"
          );
          break;
        case "openBatchChangeScore":
          if (["2", "3"].includes(this.options.billStatus)) {
            return this.$message.warning("请操作未审核的方案");
          }
          if (!this.options.check.length) {
            return this.$message.warning("请选择要操作的数据项");
          }
          //弹窗配置
          this.dialogOptions = {
            title: "批量修改积分",
            width: 500,
            show: true,
            type: "VipBatchUpdate",
            formData: {
              click: "getBatchScore",
              type: "input",
              label: "批量修改积分",
              value: undefined,
            },
          };
          break;
        case "getBatchScore":
          if (["2", "3"].includes(this.options.billStatus)) {
            return this.$message.warning("请操作未审核的方案");
          }
          if (!this.options.check.length) {
            return this.$message.warning("请选择要操作的数据项");
          }
          let adjustScore = Number(this.dialogOptions.formData.value);
          this.options.check = this.options.check.map((item) => {
            if (item.vipId) {
              this.$set(item, "adjustScore", adjustScore);
              item.usableScore = Number(item.agoUsableScore) + adjustScore;
            }
            return item;
          });
          break;
        case "batchDel": //批量删除
          if (["2", "3"].includes(this.options.billStatus)) {
            return this.$message.warning("请操作未审核的方案");
          }
          if (!this.options.check.length) {
            return this.$message.warning("请选择要操作的数据项");
          }
          this.$confirm("确定删除选中的数据?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let vipIds = this.options.check.map((item) => item.vipId);
              this.options.list = this.options.list.filter(
                (item) => !vipIds.includes(item.vipId)
              );
              if (!this.options.list || this.options.list.length <= 0)
                this.options.list = [{}];
            })
            .catch(() => {});
          break;
        case "tableIndex":
          console.log("什么鬼111", row);
          this.options.tableIndex = row;
          break;
        default:
          break;
      }
    },
    // 保存按钮
    async submitForm(isAdd) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let form = {
            billNo: this.options.billNo, // 方案号
            billId: this.options.billId, // 方案ID
            billDate: this.options.billDate, // 方案时间
            adjustScore: this.options.adjustScore,
            billRemark: this.options.billRemark,
            billDetailItems: this.options.list,
          };
          form.billDetailItems = form.billDetailItems.filter((item) => item.vipId);
          if (form.billId) {
            try {
              const { data } = await scoreAdjustUpdate(form);
              this.$set(this.options, "billStatus", data.billStatus);
              this.$message.success("修改成功");
              //新增
              if (isAdd) {
                this.reset();
                this.getBillno();
              }
            } catch (err) {}
          } else {
            try {
              const { data } = await scoreAdjustSave(form);
              this.$set(this.options, "billStatus", data.billStatus);
              this.$set(this.options, "billId", data.billId);
              this.$message.success("新增成功");
              //新增
              if (isAdd) {
                this.reset();
                this.getBillno();
              }
            } catch (err) {}
          }
        } else this.$message.error("请把带有红星的必填项输入完整!");
      });
    },
    // 获取订单编号
    async getBillno() {
      this.options.billNo = await getBillNo(150204);
      this.options.billDate = getFormattedDate(2, "-");
    },
    // 审核/反审核
    async auditForm() {
      if (this.options.billStatus == "0") {
        this.$confirm(`确定审核该方案`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            try {
              const { data } = await scoreAdjustUpdateStatus({
                billIds: [this.options.billId],
                billStatus: 2,
              });
              this.options = {
                ...this.options,
                billStatus: data.billStatus,
              };
              this.$message.success(`审核成功`);
            } catch (err) {}
          })
          .catch(() => {});
      } else {
        this.$message.warning("请操作未审核的方案");
      }
    },
    //初始化数据
    reset() {
      this.loading = false; //遮罩层
      this.options = {
        ...this.options,
        adjustScore: undefined, //本次调整积分
        billRemark: undefined, //备注
        billDate: "", // 方案日期（当前日期）
        billNo: undefined, // 方案编号
        billId: undefined, //方案id
        billStatus: undefined,
        mutiSelect: true, //是否多选
        list: [{}],
        check: [], //选中的数据
      };
      //弹窗配置
      this.dialogOptions = {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      };
      this.pageH = 0; //页面高度
      this.btnsH = 0; //搜索框高度
      this.basicH = 0; //基础框高度
      this.paginationH = 50; //分页高度
      this.resetForm("form");
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
}
.table {
  padding: 10px;
}
</style>
